.menu-close-open {
    position: absolute;
    color: white;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    width: 100%;
    align-items: center;
}
.close-icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background: #c91d67;
    margin-bottom: 60px;
}

.logout-container {
    margin-top: auto !important;
    height: 10vh;
}

.logout-hr {
    /* position: absolute; */
    /* width: 212px;
  height: 0px;
  left: 50px;
  top: 818px; */

    border: 1px solid #f3ecef;
}

.sidebar-logo-container {
    height: 72px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

@media only screen and (min-width: 1040px) and (max-width: 1260px) {
    .sidebar-logo-container {
        height: 200px !important;
        /* border-bottom: 1px solid rgba(255, 255, 255, 0.2); */
    }
}
@media only screen and (min-width: 320px) and (max-width: 1040px) {
    .sidebar-logo-container {
        height: 240px !important;
        /* border-bottom: 1px solid rgba(255, 255, 255, 0.2); */
    }
}

.sidebar-list-text {
    font-family: 'Lato' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    letter-spacing: 0.36px;

    color: #ffffff;
    /* Inside auto layout */
    /* flex: none; */
    order: 1;
    flex-grow: 0;
}
.sidebar-list-text-active {
    font-family: 'Lato' !important;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    letter-spacing: 0.36px;

    color: #ffffff;
    /* Inside auto layout */
    /* flex: none; */
    order: 1;
    flex-grow: 0;
}

@media only screen and (min-width: 320px) and (max-width: 428px) {
    .sidebar-list-text {
        line-height: 16px !important;
        font-size: 14px !important;
    }
}
