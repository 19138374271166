@font-face {
    font-family: Lato;
    src: url('./assets/fonts/lato/lato.woff');
    /* src: url("./assets/fonts/lato/lato.ttf") !important; */
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
* {
    margin: 0;
    padding: 0;
}

.font-lato {
    font-family: 'Lato' !important;
}
.font-poppins {
    font-family: 'Poppins', sans-serif !important;
}
.mt-1 {
    margin-top: 0.25rem !important;
}
.ml-1 {
    margin-left: 0.25rem !important;
}
.mt-2 {
    margin-top: 0.5rem !important;
}
.ml-2 {
    margin-left: 0.5rem !important;
}
.mr-2 {
    margin-right: 0.5rem !important;
}
.mt-3 {
    margin-top: 1rem !important;
}
.ml-3 {
    margin-left: 1rem !important;
}
.mr-3 {
    margin-right: 1rem !important;
}
.mt-4 {
    margin-top: 1.5rem !important;
}
.ml-4 {
    margin-left: 1.5rem !important;
}
.mr-4 {
    margin-right: 1.5rem !important;
}
.mt-5 {
    margin-top: 3rem !important;
}
.mr-5 {
    margin-right: 3rem !important;
}
.ml-5 {
    margin-left: 3rem !important;
}
.pl-5 {
    padding-left: 3rem !important;
}
.mt-auto {
    margin-top: auto !important;
}
.p-1 {
    padding-top: 0.25rem !important;
}
.p-2 {
    padding-top: 0.5rem !important;
}
.p-3 {
    padding-top: 1rem !important;
}
.p-4 {
    padding-top: 1.5rem !important;
}
.p-5 {
    padding-top: 3rem !important;
}
.p-auto {
    padding-top: auto !important;
}

.form-center {
    margin: auto;
    width: 100%;
    height: 100%;
    padding: 30px;
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .form-center {
        padding: 18px;
    }
}
.checkin-text {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;

    color: #ffffff !important;
}
body h4 {
    color: #000 !important;
}
.ant-input {
    border-radius: 5px;
    color: black !important;
    border: none;
}
.ant-layout-header {
    padding: 0px 21px !important;
    background: #f6f6f6 !important;
    color: rgba(0,0,0,.85) !important;
    width: 100%;
}
.ant-avatar > img {
    filter: invert(1);
}
.header-background {
    border: 1px solid rgba(255, 255, 255, 0.2);
    height: auto !important;
}
.search-box-group {
    background: linear-gradient(137.57deg, #4a0948, #000000, #1c0411, #9b0f4b) !important;
    color: white !important;
    border-radius: 5px !important;
    border: 1px solid rgba(255, 255, 255, 0.2);
}
.search-box-input {
    border-radius: 6px;
    border-right: none !important;
    color: #000 !important;
    border: 1px solid #808080 !important;
    box-shadow: inset 0px -1.59468px 0px rgba(255, 255, 255, 0.1);
}
.group-input {
    border: none !important;
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 19px;
}
.group-input:focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}
.group-input:focus-visible {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}
.search-box-input::placeholder {
    color: white !important;
}
.dropdown-btn {
    max-height: 100%;
    cursor: pointer;
    max-width: 100% !important;
    border-radius: 5px !important;
    color: white !important;
    border: 1px solid rgba(255, 255, 255, 0.2) !important;
    background: #61273e !important;
    box-shadow: inset 0px -1.59468px 0px rgba(255, 255, 255, 0.1);
}
.dropdown-btn:focus {
    box-shadow: none !important;
}
.maintenance-media-dropdown-menu {
    color: white !important;
    background: linear-gradient(137.57deg, #4a0948, #000000, #1c0411, #9b0f4b) !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .short-video {
        height: 150px !important;
        width: 200px !important;
    }
    .modal-checked {
        bottom: 85%;
    }
    .signin-title-heading {
        font-family: 'Lato' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 35px !important;
        color: #ffffff !important;
    }
    .list-item-box {
        padding: 10px;
        color: white;
        background: #c91d67;
        text-align: center;
        background-blend-mode: overlay;
        mix-blend-mode: normal;
        box-shadow: 0px 0px 14px 2px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        justify-content: center;
        align-items: center;
    }
    .home-banner-description {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 1.5rem;
        letter-spacing: 0.36px;
        color: #ffffff;
    }

    /* ...; */
}
.signin-title-description {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 800 !important;
    font-size: 77px !important;
    color: #ffffff !important;
}
@media screen and (min-width: 768px) and (max-width: 1080px) {
    .signin-title-description {
        font-family: 'Lato' !important;
        font-style: normal !important;
        font-weight: 800 !important;
        font-size: 45px !important;
        color: #ffffff !important;
    }
}
@media screen and (min-width: 429px) and (max-width: 767px) {
    .signin-title-description {
        font-family: 'Lato' !important;
        font-style: normal !important;
        font-weight: 800 !important;
        font-size: 40px !important;
        color: #ffffff !important;
    }
}
@media screen and (min-width: 320px) and (max-width: 428px) {
    .signin-title-description {
        font-family: 'Lato' !important;
        font-style: normal !important;
        font-weight: 800 !important;
        font-size: 30px !important;
        color: #ffffff !important;
    }
}
@media screen and (min-width: 1081px) and (max-width: 1440px) {
    .signin-title-description {
        font-family: 'Lato' !important;
        font-style: normal !important;
        font-weight: 800 !important;
        font-size: 60px !important;
        color: #ffffff !important;
    }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .modal-checked {
        bottom: 85%;
    }
    .signin-title-heading {
        font-family: 'Lato' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 35px !important;
        color: #ffffff !important;
    }
    .list-item-box {
        padding: 10px;
        color: white;
        background: #c91d67;
        text-align: center;
        background-blend-mode: overlay;
        mix-blend-mode: normal;
        box-shadow: 0px 0px 14px 2px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        justify-content: center;
        align-items: center;
    }
    .home-banner-description {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 1.25rem;
        letter-spacing: 0.36px;
        color: #ffffff;
    }

    /* ...; */
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .modal-checked {
        bottom: 85%;
    }
    .signin-title-heading {
        font-family: 'Lato' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 30px !important;
        color: #ffffff !important;
    }
    .list-item-box {
        padding: 10px;
        color: white;
        background: #c91d67;
        text-align: center;
        background-blend-mode: overlay;
        mix-blend-mode: normal;
        box-shadow: 0px 0px 14px 2px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        justify-content: center;
        align-items: center;
    }
    .home-banner-description {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 1.25rem;
        letter-spacing: 0.36px;
        color: #ffffff;
    }
    /* ...; */
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    /* ...; */
    .search-box-input {
        width: auto !important;
        height: auto !important;
    }
    .modal-checked {
        bottom: 80% !important;
    }
    .signin-title-heading {
        font-family: 'Lato' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 37.5067px !important;
        color: #ffffff !important;
    }
    .list-item-box {
        padding: 10px;
        color: white;
        background: #c91d67;
        text-align: center;
        background-blend-mode: overlay;
        mix-blend-mode: normal;
        box-shadow: 0px 0px 14px 2px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        justify-content: center;
        align-items: center;
    }
    .home-banner-description {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 1.25rem;
        letter-spacing: 0.36px;
        color: #ffffff;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .search-box-input {
        width: auto !important;
        height: auto !important;
    }
    .sidebar-container {
        width: auto !important;
    }
    .modal-checked {
        bottom: 65%;
    }
    /* ...; */

    .signin-title-heading {
        font-family: 'Lato' !important;
        font-style: normal !important;
        font-weight: 700 !important;
        font-size: 37.5067px !important;
        color: #ffffff !important;
    }
    .list-item-box {
        padding: 15px;
        color: white;
        background: #c91d67;
        text-align: center;
        background-blend-mode: overlay;
        mix-blend-mode: normal;
        box-shadow: 0px 0px 14px 2px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        justify-content: center;
        align-items: center;
    }
    .home-banner-description {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 1.25rem;
        letter-spacing: 0.36px;
        color: #ffffff;
    }
}
@media screen and (min-width: 0px) and (max-width: 1500px) {
    .table-header {
        width: 1500px;
    }
    .table-row {
        width: 1500px;
    }
    .table-container {
        overflow-x: scroll;
    }
}
.signature-box-text {
    height: 112px;
}
@media screen and (min-width: 0px) and (max-width: 372px) {
    .signature-box {
        width: 200px !important;
        height: 112px !important;
        max-width: 100%;
        background: #2e222b;
        opacity: 0.5;
        border-radius: 10px;
    }
}
@media screen and (min-width: 0px) and (max-width: 1260px) {
    .sidebar {
        display: none;
    }
}
@media screen and (min-width: 0px) and (max-width: 570px) {
    .checkin-ok-btn {
        width: 170px !important;
    }
}
@media screen and (min-width: 1261px) and (max-width: 4000px) {
    .sidebar {
        display: block;
    }
}
@media only screen and (min-width: 1261px) {
    .medium-sidebar-icon {
        display: none !important;
    }
    .menu-close-open {
        display: none !important;
    }
}

.home-banner {
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 250px;
    background: #ffffff;
    border: 11px solid #74204a;
    border: 0;
    border-radius: 10px;
    padding: 40px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(./assets/images/banner-image.svg);
    max-width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.home-breadcrumb {
    /* position: absolute !important; */
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 29px !important;
    line-height: 44px !important;
    /* identical to box height */

    color: #000 !important;
}
.sm-home-breadcrumb {
    /* position: absolute !important; */
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    /* identical to box height */

    color: #000;
}
.breadcrumb-item {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 19px !important;
    color: #000 !important;
    opacity: 0.6 !important;
}
.sm-breadcrumb-item {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    line-height: 19px !important;
    color: #000 !important;
    opacity: 0.6 !important;
}
.notification-icon {
    cursor: pointer;
}
.menu-box {
    /* height: 384px;
  width: 455px; */
    max-width: 100%;
    padding: 10px 10px 10px 3px;
    background: linear-gradient(137.57deg, rgba(201, 29, 103, 0.51) -16.41%, rgba(88, 88, 88, 0.39) 118.71%);
    border-radius: 10px;
    background: #fff;
}
.list-item {
    height: 51px;
    /* width: 415px; */
    padding: 1px;
    max-width: 100%;
    cursor: pointer;
    max-width: 100%;
    display: inline-block;
    vertical-align: top;
    color: white;
    background: linear-gradient(141.99deg, rgb(203 196 196 / 24%) -27.14%, rgb(190 179 179 / 15%) 122.32%);
    border-radius: 10px;
}
.list-item-icon-box {
    height: 50px;
    width: 50px;
    border-radius: 8px;
}
.list-item:hover {
    background: #c91d67 !important;
}

.menu-box-threedots {
    cursor: pointer;
}
.bell-icon {
    cursor: pointer;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
    color: white !important;
}
.row {
    --bs-gutter-x: 0px !important;
}

.card-header-text {
    font-family: 'Lato' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
}
@media screen and (min-width: 320px) and (max-width: 428px) {
    .card-header-text {
        font-size: 18px !important;
    }
}
.input-field {
    background: none !important;
    outline: none !important;
    /* color: white !important; */
    mix-blend-mode: normal;
    border: 1px solid #000;
    /* s1 */
    color: #000 !important;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    filter: drop-shadow(2px 0px 14px #5b1a41) !important;
    border-radius: 5px;
}
.select-option, select option {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    margin-top: 0.5rem !important;
    height: 20px !important;
    background-color: #808080 !important;
    color: #fff  !important;
}

.resident-photo {
    box-sizing: border-box;
    width: 117px;
    height: 117px;
    cursor: pointer;
    background: linear-gradient(141.99deg, rgba(255, 255, 255, 0.24) -27.14%, rgba(255, 255, 255, 0.15) 122.32%);
    border: 2.45455px solid #c91d67;
    border-radius: 100px;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.warning-template-photo-container {
    box-sizing: border-box;
    width: 217px;
    height: 117px;
    cursor: pointer;
    background: linear-gradient(141.99deg, rgba(255, 255, 255, 0.24) -27.14%, rgba(255, 255, 255, 0.15) 122.32%);
    border: 2.45455px solid #c91d67;
    /* border-radius: 100px; */
    text-align: center;
    justify-content: center;
    align-items: center;
}
.camera-icon {
    box-sizing: border-box;

    position: absolute;
    width: 29.25px;
    height: 29.25px;
    border-radius: 20px;
    margin-left: 80px;
    margin-top: 80px;
    /* position: fixed; */
    background: #ffffff;
    border: 2.45455px solid #692049;
}
.short-video {
    box-sizing: border-box;
    /* width: 510px; */
    margin-top: 0.6rem !important;
    /* height: 110px; */
    width: 100% !important;
    max-width: 100% !important;
    mix-blend-mode: normal;
    border: 1px dashed rgba(255, 255, 255, 0.5);
    /* s1 */
    text-align: center;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(2px 0px 14px #5b1a41);
    border-radius: 5px;
    cursor: pointer;
}
.signature {
    max-width: 100%;
    border: 1px solid #000;
    border-radius: 5px;
}
.menubox-dropdown-options {
    background: none !important;
    border: none !important;
    outline: none !important;
}
.menubox-dropdown-options:focus {
    outline: none !important;
    box-shadow: none !important;
}

.house-rules-modal {
    /* new */
    /* width: 1000px; */
    width: 70% !important;
    mix-blend-mode: normal;
    backdrop-filter: blur(18px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 10px !important;
}
@media screen and (min-width: 320px) and (max-width: 979px) {
    .house-rules-modal {
        width: 100% !important;
    }
}

.house-rules-modal-heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    /* identical to box height */

    color: #000;
}
.ant-modal-header {
    background: #61273e !important;
    color: #ffffff !important;
    border-bottom: 1px solid #836773 !important;
    /* 1px solid #836773 */
    /* border-radius: 10px !important; */
    /* margin-bottom: -1px; */
}
.ant-modal-title {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 30px !important;
    line-height: 36px !important;
    /* identical to box height */

    color: #000 !important;
}
.rule-heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */

    text-decoration-line: underline;

    color: #ffffff;
}
.confirm-checkin-comment-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */

    /* text-decoration-line: underline; */

    color: #000;
}
.signin-title {
    width: 100%;
    background: linear-gradient(137.57deg, rgba(201, 29, 103, 0.51) -16.41%, rgba(88, 88, 88, 0.39) 118.71%);
    height: 97vh;
}

.heading {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    /* identical to box height */
    color: #000;
}
@media screen and (min-width: 320px) and (max-width: 428px) {
    .heading {
        font-size: 15px !important;
    }
    .label-text {
        font-size: 11px !important;
    }
    .incident-checkbox-text {
        font-size: 11px !important;
    }
}
@media screen and (min-width: 428px) and (max-width: 768px) {
    .heading {
        font-size: 18px !important;
    }
    .label-text {
        font-size: 12px !important;
    }
    .incident-checkbox-text {
        font-size: 12px !important;
    }
}
@media screen and (min-width: 360px) and (max-width: 428px) {
    .signin-title {
        height: 87vh !important;
    }
}
@media screen and (min-width: 320px) and (max-width: 359px) {
    .signin-title {
        height: 127vh !important;
    }
}
.list-group-item {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    color: #000;
    opacity: 0.6;
}
.list-group-description {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 19px !important;
    /* identical to box height */
    color: #000 !important;
}
.rule-description {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    /* or 150% */

    letter-spacing: 0.05em !important;

    color: #000 !important;
}
.about-employee-text {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 24px !important;
    /* or 150% */

    letter-spacing: 0.05em !important;

    color: #ffffff !important;
}
.checkin-comments-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* or 150% */

    letter-spacing: 0.05em;

    color: #ffffff;
}
.description {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    /* or 150% */

    letter-spacing: 0.05em !important;

    color: #000 !important;
}
.no-available-text {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 24px !important;
    /* or 150% */

    letter-spacing: 0.05em !important;

    color: #000 !important;
}
@media screen and (min-width: 320px) and (max-width: 428px) {
    .description {
        font-size: 13px !important;
    }
}
.rules {
    overflow-x: hidden;
    overflow-y: auto;
    height: 500px;
}
.rules::-webkit-scrollbar {
    width: 15px !important;
    height: 268px !important;
}
.resident-id {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    /* identical to box height */
    color: #000;
}
.date-time-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */
    color: rgba(255, 255, 255, 0.6);
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}
.edit-btn-confirm-checkin {
    width: 128px;
    height: 42px;
    border: none !important;
    background: #c91d67 !important;
    border: 'none';
    /* s1 */
    max-width: 100%;
    box-shadow: 2px 0px 14px #5b1a41;
    border-radius: 5px;
}
.edit-btn-text-confirm-checkin {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */
    color: #ffffff;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}
.name-heading {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #000 !important;
    margin: 10px 0;
}
.about-employee {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 19px !important;
    line-height: 23px !important;
    /* identical to box height */

    text-decoration-line: underline !important;

    color: #000 !important;
}
.name-text {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 19px !important;
    line-height: 23px !important;
    /* identical to box height */
    color: #000 !important;
}

.resident-selected-photo {
    box-sizing: border-box;

    /* position: absolute; */
    width: 117px;
    height: 117px;
    /* background: url(.jpg); */
    border: 2.45455px solid #c91d67;
    border-radius: 100px;
}

.confirm-btn {
    max-width: 100%;
    height: 52px;
    width: 100%;
    border: none !important;
    display: inline-block;
    align-self: flex-end;
    background: #c91d67 !important;
    /* s1 */
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    color: #ffffff;
    box-shadow: 2px 0px 14px #5b1a41;
    border-radius: 5px;
}
.confirm-btn-col {
    display: flex;
    height: 100%;
}
.another-confirm-btn {
    max-width: 100%;
    height: 52px;
    width: 100%;
    border: none !important;
    background: #c91d67 !important;
    /* s1 */
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    color: #ffffff;
    box-shadow: 2px 0px 14px #5b1a41;
    border-radius: 5px;
}
.another-confirm-btn {
    display: none !important;
}
.another-confirm-btn-col {
    display: none !important;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
    .confirm-btn {
        display: none !important;
    }
    .confirm-btn-col {
        display: none !important;
    }
    .another-confirm-btn {
        display: block !important;
    }
    .another-confirm-btn-col {
        display: block !important;
    }
}
.confirm-btn-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
}
.resident-signature-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000;
    /* margin-top: 45px !important; */
}
.signature-box {
    width: 280px;
    height: 112px;
    max-width: 100%;
    background: #2e222b;
    opacity: 0.5;
    border-radius: 10px;
}
.ant-layout-sider-children {
    background: linear-gradient(137.57deg, #410a3e, #460c3e, #4f1a37, #c91d67) !important;
} 
.success-notification-good {
    width: 146px;
    height: 146px;
    max-width: 100%;
    max-height: 100%;
    color: white;
    background: white;
    text-align: center;
    /* position: absolute; */
    background-blend-mode: overlay;
    mix-blend-mode: normal;
    box-shadow: 0px 0px 14px 2px rgba(0, 0, 0, 0.15);
    border-radius: 100px;
    justify-content: center;
    align-items: center;
}

.modal-checked {
    position: absolute;
    display: flex;
    margin-left: -24px;
    justify-content: center;
    align-items: center;
    width: 100%;
    bottom: 80%;
}

.modal-checked-incident {
    position: absolute;
    display: flex;
    margin-left: -24px;
    justify-content: center;
    align-items: center;
    width: 100%;
    bottom: 85%;
}
.maintenence-modal-checked {
    position: absolute;
    display: flex;
    margin-left: -24px;
    justify-content: center;
    align-items: center;
    width: 100%;
    bottom: 83%;
}
.checkin-successfully-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 40px;
    /* identical to box height */
    color: #000;
}
.maintenance-confirm-modal-heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
    margin-top: 60px;
    /* identical to box height */
    color: #000;
}
.good-icon {
    height: 54px;
    width: 78px;
}
@media screen and (min-width: 320px) and (max-width: 428px) {
    .maintenance-confirm-modal-heading {
        font-size: 19px !important;
        margin-top: 80px;
    }
    .checkin-successfully-text {
        font-size: 18px !important;
    }
    .success-notification-good {
        width: 70px !important;
        height: 70px !important;
        border-radius: 60px !important;
    }
    .good-icon {
        height: 30px;
        width: 50px;
    }
}
@media screen and (min-width: 428px) and (max-width: 768px) {
    .maintenance-confirm-modal-heading {
        font-size: 25px !important;
        margin-top: 60px;
    }
    .checkin-successfully-text {
        font-size: 20px !important;
    }
    .success-notification-good {
        width: 100px !important;
        height: 100px !important;
        border-radius: 80px !important;
    }
    .good-icon {
        height: 35px;
        width: 55px;
    }
}
.checkin-successfully-description {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    /* or 161% */
    text-align: center;
    letter-spacing: 0.01em;
    color: #000;
}
.checkin-ok-btn {
    width: 200px;
    height: 42px;
    background: #c91d67 !important;
    border: none !important;
    box-shadow: 2px 0px 14px #5b1a41;
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    border-radius: 5px;
}
.ok-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    color: #ffffff;
}

#components-layout-demo-custom-trigger .trigger {
    /* padding: 0 24px; */
    font-size: 18px;
    /* line-height: 64px; */
    cursor: pointer;
    transition: color 0.3s;
}

/* #components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
} */

#components-layout-demo-custom-trigger .logo {
    /* height: 32px; */
    /* margin: 16px; */
    background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
    background: linear-gradient(137.57deg, #4a0948, #000000, #1c0411, #9b0f4b) !important;
}
.ant-layout-content,
.site-layout-background {
    margin: 0px !important;
    background: #f6f6f6 !important;
}
.card-castle {
    /* background: linear-gradient(137.57deg, rgba(201, 29, 103, 0.51) -16.41%, rgba(88, 88, 88, 0.39) 118.71%) !important; */
    border-radius: 10px;
    color: #000;
    border: none !important;
    margin-bottom: 50px;
}
.card-castle .p-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.checkout-box {
    /* position: absolute; */
    /* width: 1150px !important; */
    /* height: 282px !important; */
    max-height: 100%;

    background: #fff !important;
    /* opacity: 0.5 !important; */
    border-radius: 10px !important;
}
.incident-card-checked-box {
    width: 100% !important;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
    .incident-card-checked-box {
        width: 93% !important;
    }
}
.label-text {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 23px !important;
    /* identical to box height */
    color: #000 !important;
}
.incident-checkbox-text {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 23px !important;
    /* identical to box height */
    color: #000 !important;
}
.electrical-checkbox-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    /* identical to box height */
}

.btn-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    color: #ffffff;
}
.confirm-checkout {
    /* position: absolute; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.confirm-checkout-modal {
    /* position: absolute; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70% !important;
    margin-bottom: 70px !important;
    /* height: 800px; */
}
@media screen and (min-width: 320px) and (max-width: 1200px) {
    .confirm-checkout-modal {
        width: 100% !important;
    }
}
.checkout-modal {
    overflow-x: hidden;
    overflow-y: auto;
    height: 600px;
}

.hr-checkout {
    border: 1px solid #836773 !important;
    transform: rotate(180deg) !important;
}
.ant-modal-close-x {
    /* background: #c91d67 !important; */
    border-radius: 40px !important;
    display: none !important;
    width: 24px !important;
    height: 25px !important;
    font-size: 18px !important;
    font-style: normal !important;
    line-height: 18px !important;
    text-align: center !important;
    text-transform: none !important;
    text-rendering: auto !important;
    /* margin: 11px; */
}
.report-btn {
    width: 200px !important;
    height: 42px !important;
    max-width: 100%;
    max-height: 100%;
    background: #c91d67 !important;
    /* s1 */
    border: none !important;
    box-shadow: 2px 0px 14px #5b1a41 !important;
    border-radius: 5px !important;
    font-family: 'Lato' !important;
    /* font-family: "Poppins", sans-serif !important; */
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #ffffff !important;
}
.search-small-btn {
    background: #c91d67 !important;
    /* s1 */
    box-shadow: 2px 0px 14px #5b1a41 !important;
    border-radius: 5px !important;
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #ffffff !important;
}
.employee-list-text {
    font-style: normal !important;
    font-size: 30px !important;
    line-height: 36px !important;
    color: #000 !important;
}

.table-heading {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: #000 !important;
}
.print-table-heading {
    font-family: 'Lato' !important;
    /* font-family: "Poppins", sans-serif !important; */
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: black !important;
}
.table-cell {
    font-family: 'Lato' !important;
    /* font-family: "Poppins", sans-serif !important; */
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #000 !important;
}
.print-table-cell {
    font-family: 'Lato' !important;
    /* font-family: "Poppins", sans-serif !important; */
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;

    color: black !important;
}
.table-header {
    border-radius: 10px !important;
    justify-content: center !important;
    align-items: center !important;
    /* width: 2000px; */
}
.table-row {
    background: linear-gradient(141.99deg, rgba(255, 255, 255, 0.24) -27.14%, rgba(255, 255, 255, 0.15) 122.32%);
    padding: 4px 12px;
    /* width: 1400px; */
    border-radius: 10px !important;
    background: #f7f7f7 ;
}
.table-row.mt-3 {
    margin-top: 9px !important;
}
button.table-action-btn.btn.btn-secondary .ml-1.mr-1 {
    background: #000 !important;
}
body .Components_activeCircleDot__uyLaB{
    width: 15px;
    height: 15px;
    background: #c91d67;
}
body .Components_whiteCircleDots__IPMFs {
    width: 10px;
    height: 10px;
    background: #c91d67b3;
}
.incident-row {
    /* padding: 20px; */
    /* width: 1400px; */
    border-radius: 10px !important;
}
.table-row:hover {
    /* background: #c91d67 !important; */
    cursor: pointer;
    border-radius: 10px !important;
    box-shadow: 0 0 20px #00000030;
}
.incident-row:hover {
    background: #c91d67 !important;
    cursor: pointer;
    border-radius: 10px !important;
}
.dropdown-menu-home {
    background: black !important;
    border: none !important;
}
.dropdown-item {
    /* color: white !important; */
    font-family: 'Lato' !important;
    /* font-family: "Poppins", sans-serif !important; */
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    /* identical to box height */
    color: #ffffff !important;
}
.dropdown-item:hover {
    background-color: #c91d67 !important;
}
.employee-name {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 29px !important;
    /* identical to box height */
    padding: 11px 0px;
    color: #000 !important;
}

.list-box-property-detail {
    justify-content: center;
    align-items: center;
    /* cursor: pointer; */
    /* box-sizing: border-box; */
    /* width: 328px;
  height: 120px; */
    padding: 15px;
    background: linear-gradient(141.99deg, rgba(255, 255, 255, 0.24) -27.14%, rgba(255, 255, 255, 0.15) 122.32%);
    border: 1px solid #c91d67;
    border-radius: 10px;
}
.list-box-property-detail img {
    filter: contrast(0.5);
}

.castle-text {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 29px !important;
    /* identical to box height */
    padding: 11px;
    color: #000 !important;
}
.castle-heading-text {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 29px !important;
    /* identical to box height */

    color: #000 !important;
}
@media screen and (min-width: 320px) and (max-width: 428px) {
    .castle-heading-text {
        font-size: 18px !important;
    }
    .employee-list-text {
        font-size: 20px !important;
    }
    .report-btn {
        font-size: 12px !important;
        width: 150px !important;
    }
}
@media screen and (min-width: 428px) and (max-width: 768px) {
    .castle-heading-text {
        font-size: 19px !important;
    }
    .employee-list-text {
        font-size: 22px !important;
    }
    .report-btn {
        font-size: 14px !important;
    }
}

.castle-btn {
    background-color: #c91d67 !important;
    border: none !important;
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    /* identical to box height */

    color: #ffffff !important;
}
.req-maintenance-btn {
    background-color: #c91d67 !important;
    border: none !important;
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    padding: 8px 30px !important;
    /* identical to box height */

    color: #ffffff !important;
}

/* ::-webkit-calendar-picker-indicator {
  filter: invert(1);
} */
.email-placeholder::placeholder {
    color: #5a5a5a !important;
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 400 !important;
}

.rrui__menu-button {
    color: white !important;
}

.form-check-input {
    width: 17px !important;
    height: 17px !important;
}
.form-check-input:checked[type='checkbox'] {
    background-image: url('./assets/icons/good-checkbox.svg') !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-color: #f7f7f7 !important;
}
.form-check-input:checked[type='select'] {
    background-image: url('./assets/icons/good-checkbox.svg') !important;
    background-color: rgb(0, 0, 0) !important;
}

.input-field[type='text']:focus {
    border: 1px solid #000 !important;
    box-shadow: none !important;
}
.input-field[type='text'] {
    border: 1px solid #000 !important;
    box-shadow: none !important;
}
input[type='checkbox']:focus {
    border: 1px solid rgb(99 26 63) !important;
    box-shadow: none !important;
}
input[type='checkbox'] {
    border: 1px solid rgb(99 26 63) !important;
    box-shadow: none !important;
}

.input-field:focus {
    border: 1px solid rgb(99 26 63) !important;
    box-shadow: none !important;
}

.input-field {
    border: 1px solid rgb(99 26 63) !important;
    box-shadow: none !important;
}

.click-upload-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-top: 0.5rem;
    color: #000;
    opacity: 0.6;
}
.checkout-card-box {
    width: 91.6%;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
    .checkout-card-box {
        width: 100% !important;
    }
}
input[type='date']::-webkit-calendar-picker-indicator {
    background-image: url('./assets/icons/date-picker-icon.png') !important;
}
.form-select {
    background-image: url('./assets/icons/select-box-icon.png') !important;
    /* height: 30px; */
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-repeat: no-repeat !important;
    background-position: right 0.75rem center !important;
}

input[type='datetime-local']::-webkit-calendar-picker-indicator {
    background-image: url('./assets/icons/date-picker-icon.png') !important;
}

.table-action-btn {
    background: none !important;
    border: none !important;
}
.table-action-btn:focus {
    box-shadow: none !important;
}

.card-header {
    border-bottom: 1px solid #836773 !important;
}

.house-rules-text {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 30px !important;
    line-height: 36px !important;
    /* identical to box height */

    color: #000 !important;
}

/* width */

/* Track */
::-webkit-scrollbar-track {
    background: #836773 !important;
    border-radius: 40px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #ffffff !important;
    border-radius: 8px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
}

/* ///////////////////////////////////// */

.input-icons {
    position: relative;
    display: inline-block;
}

.icon {
    margin: 7px 10px;
    position: absolute;
    justify-content: flex-end;
    /* align-items: center; */
}
/* @media screen and (min-width: 992px) and (max-width: 1039px) {
  .icon {
    margin-left: 88%  !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 1040px)  {
  .icon {
    margin-left: 83%  !important;
  }
} */

.input-field::placeholder {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 19px !important;
}

.incident-yes-confirm-btn {
    width: 200px;
    border: 1px solid #c91d67 !important;
    background: transparent !important;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    color: #c91d67 !important;
    /* s1 */
    filter: drop-shadow(2px 0px 14px #5b1a41);
    border-radius: 5px;
}
.short-video-checkin {
    width: 100% !important;
    border: 1px dashed rgb(0, 0, 0);
    border-radius: 5px;
    cursor: pointer;
    background: #f6f6f6 !important;
}
.short-video-checkin p.label-text {
    color: #000 !important;
}
.short-video-checkin img {
    filter: invert(1);
}
.short-video-checkout .label-text {
    color: #000 !important;
}
button.dropdown-item.castle-text.dropdown-item {
    color: #fff !important;
}
.property-upload-video-box {
    box-sizing: border-box;
    width: 100% !important;
    max-width: 100% !important;
    border: 1px dashed rgba(0, 0, 0, 0.5);
    background: f6f6f6;
}
.property-upload-video-box .label-text{
    color: #000 !important;
}
.short-video-checkout {
    box-sizing: border-box;
    margin-top: 0.5rem !important;
    max-width: 100% !important;
    mix-blend-mode: normal;
    padding-top: 4px;
    padding-left: 18px;
    padding-right: 18px;
    border: 1px dashed rgba(0, 0, 0, 0.5);
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    background: #f6f6f6;
}
.short-video-checkout img {
    filter: invert(1);
}
.menubox-dropdown-options img {
    filter: invert(1);
}
.menubox-dropdown-options {
    width: 100% !important;
}

.video-title {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 30px !important;
    line-height: 36px !important;
    /* identical to box height */

    color: #ffffff !important;
}
.ant-modal-content {
    background: none !important;
}

.register-status-btn {
    width: 200px !important;
    height: 42px !important;
    max-width: 100%;
    max-height: 100%;
    background: #c91d67 !important;
    /* s1 */
    border: none !important;
    box-shadow: 2px 0px 14px #5b1a41 !important;
    border-radius: 5px !important;
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #ffffff !important;
}

.castle-btn {
    background: #c91d67 !important;
    font-family: 'Lato' !important;
}

.req-maintenance-btn {
    background: #c91d67 !important;
    font-family: 'Lato' !important;
}

.castle-btn:focus {
    box-shadow: none !important;
}
.req-maintenance-btn:focus {
    box-shadow: none !important;
}

.add-customer {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 30px !important;
    line-height: 36px !important;
    /* identical to box height */

    color: #000 !important;
}
@media screen and (min-width: 320px) and (max-width: 428px) {
    .add-customer {
        font-size: 17px !important;
    }
    .add-customer-heading {
        font-size: 16px !important;
    }
}
@media screen and (min-width: 429px) and (max-width: 768px) {
    .add-customer {
        font-size: 22px !important;
    }
    .add-customer-heading {
        font-size: 18px !important;
    }
}
.add-property-unit-modal-heading {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 30px !important;
    line-height: 36px !important;
    /* identical to box height */

    color: #000 !important;
}
.incident-confirm-icon {
    height: 76px !important;
    width: 81px !important;
}
@media screen and (min-width: 428px) and (max-width: 768px) {
    .add-property-unit-modal-heading {
        font-size: 23px !important;
    }
    .name-text {
        font-size: 15px;
    }
    .name-heading {
        font-size: 15px !important;
    }
    .incident-confirm-icon {
        height: 40px !important;
        width: 40px !important;
    }
}
@media screen and (min-width: 320px) and (max-width: 428px) {
    .add-property-unit-modal-heading {
        font-size: 18px !important;
    }
    .incident-confirm-icon {
        height: 30px !important;
        width: 30px !important;
    }
}

.tag-text-input-field::placeholder {
    font-size: 24px !important;
}
.tag-text-input-field {
    font-size: 20px !important;
}
.tib-text {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    /* identical to box height, or 150% */
    letter-spacing: 0.05em !important;
    color: #ffffff !important;
}
.tib {
    /* width: 129px;
  height: 27px; */
    /* padding: 0.4rem 0.6rem 0.4rem 0.6rem !important; */
    background: #311b1b;
    color: white;
    border-radius: 5px;
    /* justify-content: center; */
    align-items: center;
    /* display: flex; */
}
/* @media only screen and (min-width:1040px) {
  .side-bar-scroll {
    overflow-y: scroll !important;
    height: 600px !important;
  }
  .side-bar-scroll::-webkit-scrollbar {
    width: 2px !important;
    height: 268px !important;
  }
} */

.ant-notification-notice-message {
    color: white !important;
    font-size: 20px !important;
    margin-left: 0px !important;
}
.ant-notification-notice-description {
    color: white !important;
    font-size: 20px !important;
    margin-left: 0px !important;
}
.ant-notification-notice-icon-info {
    display: none !important;
}
.ant-notification-notice-close-icon {
    color: white !important;
    font-size: 20px !important;
}

/* *********************************** */
.checkbox-tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    /* cursor: pointer; */
}

.checkbox-tooltip .checkbox-tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.checkbox-tooltip .checkbox-tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.checkbox-tooltip:hover .checkbox-tooltiptext {
    visibility: visible;
    opacity: 1;
}
.rrui__activity-indicator {
    color: #c91d67;
}
.rrui__activity-indicator__arc {
    border-width: 2px !important;
}

.rrui__activity-indicator__arc {
    position: absolute;
    width: 200px !important;
    height: 200px !important;
    box-sizing: border-box !important;
    margin-top: 2rem;
    border: 1.15em solid currentColor !important;
    border-radius: 5px;
    animation: rrui__activity-indicator 1.2s cubic-bezier(1.5, 1.15, 0.5, 0.85) infinite !important;
    border-color: currentColor transparent transparent transparent !important;
}

/* .ant-modal-body {
    margin-bottom: 170px !important;
} */

.ant-modal-body {
    background: #fff !important;
}   
.add-employee {
    color: #000000 !important;
}
.weekly-full-report-container {
    width: 100% !important;
    height: 470px !important;
    border: 1px solid grey;
    border-radius: 20px !important;
    overflow-y: scroll !important;
}
.weekly-full-report-container::-webkit-scrollbar {
    display: none !important;
}
.weekly-name-heading {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 900 !important;
    font-size: 18px !important;
    line-height: 19px !important;
    opacity: 0.7 !important;
}
.weekly-name-text {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 19px !important;
    opacity: 0.8 !important;
}

.resident-status-checkout-text {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 900 !important;
    font-size: 18px !important;
    line-height: 19px !important;
    color: #c91d67 !important;
    /* opacity: 0.7 !important;  */
}

.role-list-item {
    cursor: pointer;
}
.role-list-item:hover {
    opacity: 0.5;
    font-size: 17px !important;
}

.role-list-item-active {
    opacity: 0.5;
}

/* @media screen and (min-width: 320px) and (max-width: 428px) {
    .footer-component {
        display: none !important;
    }
} */

.signin-field {
    width: 294px !important;
    height: 42px !important;
    border-radius: 5px !important;
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 400 !important;
}
.signin-btn {
    width: 294px !important;
    height: 42px !important;
    background: #c91d67 !important;
    border-radius: 5px !important;
    border: none !important;
}
.signin-btn:hover {
    width: 294px !important;
    height: 42px !important;
    background: transparent !important;
    color: #c91d67;
    border-radius: 5px !important;
    border: 1px solid #c91d67 !important;
}
.sm-show {
    display: none !important;
}
@media screen and (min-width: 320px) and (max-width: 428px) {
    .signin-field {
        width: 240px !important;
        height: 42px !important;
        border-radius: 5px !important;
        font-family: 'Lato' !important;
        font-style: normal !important;
        font-weight: 400 !important;
    }
    .signin-btn {
        width: 240px !important;
        height: 42px !important;
    }
    .form-center {
        padding: 15px !important;
    }
    .name-text {
        font-size: 14px !important;
    }
    .name-heading {
        font-size: 13px !important;
    }
    .sm-hide {
        display: none !important;
    }
    .sm-show {
        display: block !important;
    }
}

.sign-sm-form {
    display: none !important;
}
.sign-lg-form {
    display: flex !important;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
    .sign-sm-form {
        display: flex !important;
        justify-content: center;
    }
    .sign-lg-form {
        display: none !important;
    }
}

.signin-welcome-text {
    font-style: normal !important;
    font-weight: 800 !important;
    font-size: 40px !important;
    font-family: 'Lato' !important;

    color: #bac8d2 !important;
}
.maintenence-card-checked-box {
    width: 90.2% !important;
}
.property-video-upload-box {
    width: 90.2% !important;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
    .signin-welcome-text {
        font-style: normal !important;
        font-weight: 800 !important;
        font-size: 22px !important;
        font-family: 'Lato' !important;

        color: #bac8d2 !important;
    }
    .maintenence-card-checked-box {
        width: 100% !important;
    }
}

.property-details-pic {
    width: 100%;
    height: 200px;
    border-radius: 10px;
}

.home-banner-title {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 50px !important;
    color: #ffffff !important;
}
.checkout-confirmation-heading {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 30px !important;
    line-height: 36px !important;
    /* identical to box height */

    color: #ffffff !important;
}
.are-you-sure {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 40px !important;
    line-height: 49px !important;
    /* identical to box height */
    color: #000 !important;
}
.confirm-checkout-modal div img {
    filter: invert(1);
}
.list-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px;
    line-height: 24px;
    color: #000;
}
.list-item-title {
    font-family: 'Lato';
    font-style: normal;
    font-size: 22px;
    font-weight: bold;
    line-height: 36px;
    color: #000;
    margin: 0;
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
    .home-banner-title {
        font-size: 40px !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .home-banner-title {
        font-size: 35px !important;
    }
    .are-you-sure {
        font-size: 32px !important;
    }
    .list-item-title {
        font-size: 25px !important;
    }
}
@media screen and (min-width: 428px) and (max-width: 767px) {
    .home-banner-title {
        font-size: 32px !important;
    }
    .checkout-confirmation-heading {
        font-size: 18px !important;
    }
    .are-you-sure {
        font-size: 22px !important;
    }
    .employee-name {
        font-size: 18px !important;
    }
    .list-text {
        font-size: 14px !important;
    }
    .list-item-title {
        font-size: 22px !important;
    }
    .list-item {
        height: 50px !important;
    }
    .list-item-icon-box {
        height: 50px !important;
        width: 50px !important;
        border-radius: 10px !important;
    }
}
@media screen and (min-width: 320px) and (max-width: 427px) {
    .home-banner-title {
        font-size: 28px !important;
    }
    .checkout-confirmation-heading {
        font-size: 13px !important;
    }
    .are-you-sure {
        font-size: 20px !important;
    }
    .employee-name {
        font-size: 18px !important;
    }
    .list-text {
        font-size: 13px !important;
    }
    .list-item-title {
        font-size: 20px !important;
    }
    .list-item {
        height: 50px !important;
    }
    .dropdown-item {
        font-size: 12px !important;
    }
    .list-item-icon-box {
        height: 50px !important;
        width: 50px !important;
        border-radius: 10px !important;
    }
    .card-header .d-flex.flex-wrap {
        margin: 0 !important;
    }
    section main.ant-layout-content.site-layout-background {
        padding: 0 !important;
        margin: 0 !important;
    }
}

.cursor-pointer {
    cursor: pointer !important;
}

.input-validate-error {
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    color: rgb(218, 64, 64) !important;
}

@media screen and (min-width: 320px) and (max-width: 576px) {
    .sm-footer-center-text {
        width: 100% !important;
        text-align: center !important;
    }
}

.print-resident {
    /* display: none; */
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100000;
}

@media screen and (min-width: 320px) and (max-width: 992px) {
    .modal-video-player {
        width: 100% !important;
    }
}


/* Custom CSS */
main.ant-layout-content {
    background: #f7f7f7 !important;
}
body .footer-component {
    padding: 0 !important;
    background: #fff;
    box-shadow: 0 0 10px #00000017;
}
.footer-component p {
    margin: 0;
    color: #000;
    font-size: 14px !important;
}
.footer-component p span {
    color: #000;
    font-size: 14px !important;
}
.list-item p.list-text {
    margin: 0 0 .5rem;
}
.anticon-question-circle svg {
    fill: #000;
}
.table-cell .dropdown button img {
    filter: invert(1);
}
img[src="/static/media/pagination-back-arrow.7121b14e2741ee9ad5b03c7b21955de0.svg"], 
img[src="/static/media/pagination-back-arrow.7121b14e2741ee9ad5b03c7b21955de0.svg"] {
    filter: brightness(0.5);
}
.Components_nextArrow__ZxF99, .Components_backArrow__9EBn9 {
    filter: brightness(0) !important;
}
.search-box-input svg path {
    fill: #000;
}

body form input.input-field, 
body form .input-field[type=text], 
body form .form-select, 
body form textarea.form-control {
    border: 1px solid #000 !important;
    color: #000 !important;
    outline: none !important;
    box-shadow: none !important;
    filter: none !important;
}
body form input.input-field::placeholder, 
body form .input-field[type=text]::placeholder, 
body form .form-select::placeholder, 
body form textarea.form-control::placeholder {
    color: #000 !important;
}
body form input.input-field:focus, 
body form .input-field[type=text]:focus, 
body form .form-select:focus, 
body form textarea.form-control:focus {
    border: 1px solid #000 !important;
}
body form .form-select {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E") !important;
    background-position: right 0.75rem center!important;
    background-repeat: no-repeat!important;
    background-size: 18px !important;
}
.icon svg path {
    fill: #000;
}
.icon~input {
    position: relative;
    z-index: 1;
}
body .text-white{
    color: #000 !important;
}



.MuiStack-root {
    margin-top: -8px;
}
.MuiStack-root>div {
    min-width: 100% !important;
    max-width: 100%;
    margin: 0 !important;
    border: 1px solid #000 !important;
    background: transparent !important;
    filter: none !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 5px;
}
.MuiStack-root label {
    color: #000 !important;
    background: #fff;
}
.MuiStack-root .MuiInputBase-root input {
    border: 0;
    box-shadow: none!important;
    outline: none;
    padding-bottom: 5px;
    padding-top: 5px;
    font-size: 15px;
    height: 26px;
}
.MuiStack-root fieldset {
    border: 0 !important;
}
.input-field.form-select {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E") !important;
    background-position: right 0.75rem center!important;
    background-repeat: no-repeat!important;
    background-size: 20px 16px !important;
    cursor: pointer;
    filter: none !important;
}
.MuiPaper-root .Mui-selected,
.MuiPaper-root .MuiPickersDay-root.Mui-selected {
    background: #61273e !important;
}
.MuiPaper-root .MuiPickersDay-root:not(.Mui-selected) {
    border-color: #61273e !important;
}
.MuiMultiSectionDigitalClock-root::after {
    height: auto !important;
}
.MuiDateTimePickerToolbar-dateContainer .MuiDateTimePickerToolbar-dateContainer button.MuiButton-text.MuiButton-textPrimary[type=button], button.MuiButton-text.MuiButton-textPrimary[type=button] .Mui-selected,
.MuiDateTimePickerToolbar-timeContainer button.MuiButton-text.MuiButton-textPrimary[type=button] {
    background: transparent !important;
}
.MuiTabs-flexContainer .Mui-selected {
    background: transparent !important;
    color: #61273e!important;
}
.MuiTabs-flexContainer + span.MuiTabs-indicator,
.MuiClock-clock .MuiClockPointer-root,
.MuiClock-clock .MuiClock-pin {
    background: #61273e;
}
.MuiClock-clock .MuiClockPointer-root .MuiClockPointer-thumb {
    background: #61273e;
    border-color: #61273e;
}
.btn.btn-update {
    border: 1px solid;
    background-color: #440f37;
    color: #fff;
}
@media screen and (max-width: 428px){
    .table-header, .table-row {
        width: auto;
    }
    .card-castle .p-2 {
        white-space: normal;
    }
    .Components_tablePaginationBtns__kQe4N {
        display: flex;
        position: static !important;
    }
    .table-container .col-2, .table-container .col-1, .table-container .col-3 {
        width: 100%;
    }

}
@media only screen and (max-width: 767px) {
    .sm-show.dropdown-box select.dropdown-btn.form-select {
        margin-left: auto !important;
    }
    .sm-show.dropdown-box>.w-100.ms-4 {
        margin: 0 !important;
    }
    
}
