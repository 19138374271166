/* Extra large devices (large laptops and desktops, 1200px and up) */
/*  */
/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {
    .breadcrumb-and-search{
        width: 999px;
    }
} */

.header-three {
    display: none !important;
}
.dropdown-btn {
    width: 100%;
}
.search-box {
    /* border-right: 2px solid rgba(255, 255, 255, 0.2); */
    /* position: absolute; */
    padding-right: 15px;
}
.header-div {
    display: flex;
    grid-template-columns: 40% 30% 26% 4%;
    align-items: center;
    background: rgba(255, 255, 255, 0.001);
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.2) */
    /* border-right: 1px solid rgba(255, 255, 255, 0.2) */
}
@media only screen and (min-width: 993px) and (max-width: 1128px) {
    .header-div {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: flex-start;
    }
    .search-box {
        width: 100%;
        border-right: none !important;
    }
    .for-dropdown {
        width: 100%;
        display: grid !important;
        grid-template-columns: 70% 15% 15%;
    }
    .dropdown-btn {
        width: 100%;
    }
}
@media only screen and (min-width: 1129px) and (max-width: 1270px) {
    .header-div {
        display: flex;
        grid-template-columns: 27% 34% 34% 5% !important;
    }
}

@media only screen and (min-width: 1271px) and (max-width: 1503px) {
    .header-div {
        grid-template-columns: 27% 32% 35% 6% !important;
        display: flex !important;
        width: 100%;
    }
}
@media only screen and (min-width: 1271px) {
    .for-dropdown {
        border-left: 2px solid rgba(255, 255, 255, 0.2) !important;
        padding-left: 25px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 1260px) {
    .header-div {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: flex-start;
    }
    .search-box {
        width: 96%;
        border-right: none !important;
    }

    .ant-layout-header {
        padding: 0 10px !important;
    }

    .dropdown-btn {
        width: 92% !important;
    }
    .for-dropdown {
        width: 100%;
        display: grid !important;
        grid-template-columns: 84% 14% 7%;
    }
    .one {
        display: none !important;
    }
    .two {
        display: none !important;
    }
    /* .header-three {
    display: block !important;
  } */
}

.for-dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

.profile {
    padding-left: 10px;
}
@media only screen and (min-width: 320px) and (max-width: 540px) {
    .profile {
        padding-left: 0 !important;
    }
    .dropdown-box {
        margin-left: 0 !important;
    }
    .search-box {
        border-right: none !important;
    }
}

.dropdown-box {
    margin-left: 10px;
}

.castle-badge {
    border-radius: 10px !important;
    position: absolute !important;
    margin-top: 12px !important;
    margin-left: -12px !important;
    cursor: pointer;
}

.rrui__slideout-menu {
    background-color: #61273e !important;
}

.cross-icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background: #c91d67;
    margin-bottom: 60px;
    margin-right: 5px;
}
