/* .sidebar {
  background: linear-gradient(137.57deg, #410a3e, #460c3e, #4f1a37, #c91d67);
} */

.sidebar-list {
    border: none !important;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.sidebar-list:hover {
    background-color: #c91d67;
    color: white;
    box-shadow: 0px 0px 14px 2px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
}
.sidebar-list-active {
    background-color: #c91d67;
    color: white;
    border: none !important;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    box-shadow: 0px 0px 14px 2px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
}
.sidebar-list:active {
    background-color: #c91d67;
    color: white;
    box-shadow: 0px 0px 14px 2px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
}
.logout-btn {
    background: none !important;
    border: none !important;
    outline: none !important;
    color: white !important;
    padding: 0 !important;
    cursor: pointer !important;
    transition: 0.3s ease-in-out !important;
}
.logout-btn:hover {
    background-color: #c91d67 !important;
    color: white !important;
    box-shadow: 0px 0px 14px 2px rgba(0, 0, 0, 0.15) !important;
    border-radius: 5px !important;
}
.rrui__activity-indicator {
    color: #c91d67;
}

.rrui__activity-indicator__arc {
    border-width: 3px !important;
    width: 30px !important;
    height: 30px !important;
}
.font-family-class {
    font-family: 'Lato' !important;
}
