.footer-text {
    font-family: 'Lato' !important;
    /* font-family: "Poppins", sans-serif !important; */
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: max(0.3vw, 16.73px) !important;
    /* line-height: 20px !important; */
    /* identical to box height */

    text-align: center;
    color: #000;
}
.footer-component {
    padding: 0px !important;
    background: linear-gradient(180deg, #c91d67 0%, #520023 100%);
}

.font-family-class {
    font-family: 'Lato';
}

.small-footer-text {
    font-family: 'Lato' !important;
    /* font-family: "Poppins", sans-serif !important; */
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 10px !important;
    /* line-height: 20px !important; */
    /* identical to box height */

    text-align: center;
    color: #000;
}

.small-devices-footer {
    display: none;
}

@media screen and (min-width: 429px) and (max-width: 575px) {
    .small-devices-footer {
        display: block !important;
    }
}
