* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
h4 {
    color: white !important;
}
.MuiStack-root {
    margin-top: -8px;
}
.MuiStack-root>div {
    min-width: 100% !important;
    max-width: 100%;
    margin: 0 !important;
    border: 1px solid #000 !important;
    background: transparent !important;
    filter: none !important;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 5px;
}
.MuiStack-root label {
    color: #000 !important;
    background: #fff;
}
.MuiStack-root .MuiInputBase-root input {
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    outline: none;
    box-shadow: none !important;
    border: 0;
}
.MuiStack-root fieldset {
    border: 0 !important;
}

@media only screen and (max-width: 767px) {
    .sm-show.dropdown-box select.dropdown-btn.form-select {
        margin-left: auto !important;
    }
    .sm-show.dropdown-box>.w-100.ms-4 {
        margin: 0 !important;
    }
}